$brand_code: 'zont'
$brand_colors__main: #2ababe
$brand_colors__controls_accent: #2ababe
$brand_colors__header__background: #f0f0f0
$brand_colors__header__top_border: #a6ce3a
$brand_colors__header__help_background: #a6ce3a
$brand_colors__header__events_notifier__inactive: #cccccc
$brand_colors__header__events_notifier__highlight: #fe6021
$brand_colors__header__events_notifier__highlight_mobile: #fe6021
$brand_colors__header__events_notifier__number: #ffffff
$brand_colors__header__upgrades_notifier_highlight: #2ababe
$brand_colors__header__link: #888888
$brand_colors__header__title: #444444
$brand_colors__header__hover: yes
$brand_colors__header__logo__width: 131px
$brand_colors__header__logo__height: 45px
$brand_colors__header__logo__narrow__width: 56px
$brand_colors__header__logo__narrow__height: 20px
$brand_colors__helper_background: #1565C0
$brand_colors__leftmenu__header: #0070b7
$brand_colors__leftpanel__font_size: 140%
$brand_colors__cornernotice__header: #0070b7
$brand_colors__app__splash__background: #2ababe
$brand_colors__app__splash__backgroundGradient: #15797c
$brand_colors__app__splash__backgroundDark: #6ac8cc
$brand_colors__app__android__statusbar: #263238
$brand_colors__app__selected_bottom_tabs: rgba(#000, 0.25)
$brand_colors__app__header: #37474f


html, body
    min-width: 0

body
    background: url(./noise-bg.jpg) repeat
    color: #5a5a5a

p
    line-height: 1.35
    margin-bottom: 14px

.centered-container
    position: relative
    width: 100%
    height: 100%
    text-align: center
    overflow: auto
    white-space: nowrap

.centered-container:before
    content: ''
    display: inline-block
    height: 100%
    vertical-align: middle
    white-space: normal

.centered
    display: inline-block
    vertical-align: middle
    text-align: center
    white-space: normal


.content
    font-size: 26px


h1
    font-size: 250%
    font-weight: 100
    margin-bottom: 0.7em
    color: #444


.gray-404
    font-size: 152px
    color: transparent
    text-shadow: 0 2px 3px #e5e5e5, 0 0 0 rgba(0, 0, 0, 0.2)
    margin-top: 0.5em




.error
    font-style: italic
    font-size: 80%

.centered
    max-width: 500px


.complaint-link
    text-decoration: none
    border-bottom: 1px dashed #1eb8b9

    &:hover, &:active
        border-bottom: 1px dashed #f0662f



.complaint
    margin-top: 2em
    font-size: 70%
    font-style: italic
    color: #777

    display: none
    &.visible
        display: block

textarea
    min-width: 70%
    height: 50px


.svg-500
    overflow: visible
    position: absolute
    left: 50%
    top:  50%

.orbit
    fill: none
    stroke: #ddd
    stroke-dasharray: 20 20

.satellite-body
    fill: #e8e8e8
.satellite-head
    fill: #adc48e

.waves
    stroke: #c0c0c0
    stroke-width: 1px
    fill: none
    ellipse
        opacity: 0


.bubble
    fill: rgba(255, 255, 255, 0.7)
    stroke: #808080
    stroke-width: 1px

.bubble-text
    font-family: sans
    font-size: 10px
    fill: #444



@-webkit-keyframes spin
    from
        transform: rotate(0deg)   translate(0, -400px)
    to
        transform: rotate(360deg) translate(0, -400px)

@-webkit-keyframes spin-saying
    from
        transform: rotate(0deg)   translate(0, -400px) rotate(-0deg)   translate(20px, -50px)
    to
        transform: rotate(360deg) translate(0, -400px) rotate(-360deg) translate(20px, -50px)

.satellite
    -webkit-animation-name: spin

.saying
    -webkit-animation-name: spin-saying


.satellite, .saying
    -webkit-animation-duration: 60s
    -webkit-animation-iteration-count: infinite
    -webkit-animation-timing-function: linear


@-webkit-keyframes saying-scale
    0%
        transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px)
    12%
        transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px)
    15%
        transform: translate(-2.5px, 45px) scale(1) translate(2.5px, -45px)
    25%
        transform: translate(-2.5px, 45px) scale(1) translate(2.5px, -45px)
    35%
        transform: translate(-2.5px, 45px) scale(1) translate(2.5px, -45px)
    38%
        transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px)
    100%
        transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px)

.saying-inner
    -webkit-animation-name: saying-scale
    -webkit-animation-duration: 14s
    -webkit-animation-iteration-count: infinite
    -webkit-animation-timing-function: linear


@-webkit-keyframes satellite-inner
    from
        transform: rotate(0deg)
    to
        transform: rotate(360deg)

.satellite-inner
    -webkit-animation-name: satellite-inner
    -webkit-animation-duration: 20s
    -webkit-animation-iteration-count: infinite
    -webkit-animation-timing-function: linear
